import '../Styles/ForgotPassword.css'
import { NavLink} from "react-router-dom";
const ForgotPassword = () => {
    return (
        <div id="ForgotPassword-Container">
           <div id="Inside-ForgotPassword-Container">
                <div id='ForgotPassword'>Forgot Password</div>
                <div className={"mb-3"}>
                        <input  className={"form-control"} 
                                type={"text"}
                                placeholder={"Username"}
                                aria-label="default input example"/>
                </div>
                <div>
                    <button type="button" id='Button-Container' className={"btn btn-danger"}>GENERATE PASSWORD</button>
                </div>
                <NavLink to={'/'}>Cancel</NavLink>
           </div>

        </div>
    )
}

export default ForgotPassword;