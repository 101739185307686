import { useNavigate } from 'react-router-dom';
import '../LookingGlassStyles/StatesCommon.css'
import LookingGlassDetail from './LookingGlassDetails';
const StateCard = (props) => {
    const navigate = useNavigate();
    return(
        <div id={'LookingGlassMainContainer'}>
                <div id={"LookingGlassHeading"}>
                    <h3>{props.stateName}</h3>
                </div>
                <div id={"LookingGlassMytable"}>
                    <div id={"LookigGlassTableHeading"}>
                        <div>Router</div>
                        <div>Congif Last Updated</div>
                    </div>
                </div>
                <div id={'LookingGlassTableData'}>
                        <div>{props.stateName}</div>
                        <button onClick={() => navigate('Details')}>Looking Glass</button>
                </div>
        </div>
        )
}
export default StateCard;