import { NavLink, Outlet, useNavigate } from "react-router-dom"
import '../LookingGlassStyles/LookingGlass.css'
const LookingGlass = () => {
    const navigate = useNavigate();
    return(
            <>
                <div id={'LookingGlass-Container'}>
                    <div id={'LookingGlass'}>
                        <h2>Looking Glass</h2>
                        <h6>This is the public looking glass</h6>
                    </div>
                    
                    <div id={'LookingGlassNavbar'}>
                            <nav>
                                <NavLink to={'MaharaniBagh'}>Maharani Bagh</NavLink>
                                <h5>Kolkata</h5>
                                <h5>Chennai</h5>
                                <h5>Shilong</h5>
                                {/* To Enable Navlinks uncomment all the Navlinks and comment all the H5 tags*/}
                                {/* <NavLink to={''}>Kolkata</NavLink>
                                <NavLink to={''}>Chennai</NavLink>
                                <NavLink to={''}>Shilong</NavLink> */}
                            </nav>   
                    </div>
                    <Outlet></Outlet>
                </div>
            </>
       
    )
}

export default LookingGlass