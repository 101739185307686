import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import '../LookingGlassStyles/LookingGlassDetails.css'
const LookingGlassDetail = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const PathName = location.pathname;
    const StateName =  PathName.split('/')[4];
    console.log(StateName)
    return(
        <>
            <div id={'LookingGlassDetailsMainContainer'}>
                
                <div id='HeadingLookingGlassDetails'>
                    <h3>Looking Glass Mumbai IPv4</h3>
                    <p>This is a public looking glass</p>
                </div>
                
                <div id={'DetailsBar'}>
                    <p>Router ID: 103.77.108.204 |</p>
                    <p>Uptime: 56 days |</p>
                    <p>Last Reconfigure: 2022-06-07 21:00:19 |</p>
                    <p>Cached data Maximum age 2 mins</p>
                </div>

                <table id={'LookingGlassMainTable'}>
                    <thead id={'LookingGlassDetailTable'}>
                        <tr>
                            <th>Neighbor</th>
                            <th>Description</th>
                            <th>ASN</th>
                            {/* <th>Table</th>
                            <th>PfxLimit</th>
                            <th>state/PfxRcd</th>
                            <th>PfxExp</th> */}
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody id={'LookingGlassDetailTableData'}>
                        <tr>
                            <td>103.77.108.73</td>
                            <td>AS1828 Unitas Global</td>
                            <td>1828</td>
                            {/* <td>t_0827</td>
                            <td>3/5000</td>
                            <td>3</td> */}
                            {/* <td>11955</td> */}
                            <td><Link to={'OurPeer'}>Details</Link></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Outlet></Outlet>
        </>
    )
}
// <button onClick={() => navigate('/Details/OurPeer')}>Details</button>
export default LookingGlassDetail;