import '../Styles/Header.css'
import img from '../Logo/powerGridLogo.png'
import { useNavigate } from 'react-router-dom'
const Header = () => {
  const navigate = useNavigate()  
  return(
    <div id={'Header-Container'}>
        <div id={'Image-Container'}>
          <img  src={img} alt={'Logo..'} onClick={() => {navigate('home')}}></img>
        </div>
         <div  id={'logo-container'}>
            <p>IXP</p>
         </div>   
    </div>
    )
}

export default Header;