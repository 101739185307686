import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../LookingGlassStyles/OurPeersEdit.css'
import axios from 'axios';
import Cookies from 'js-cookie';
import qs from 'qs';

import { Navigate } from "react-router-dom";

const OurPeersEdit = (props) => {
    useEffect(() => console.log())
    // This Navigate will help us to navigate to the pages
    const navigate = useNavigate();
    //Location will help us to fetch the location from the URL 
    const location = useLocation();
    // console.log(location);
    // To display the Data on console
    // console.log(props.AsData);
    // console.log(props.peerData)
    const [data , setData] = useState(); 
    // const [As,setAs] = useState(null);
    //Fetching the key from the parent element and assigning it to the As variable
    const As = props.AsData ? props.AsData : " ";
    const [peer,setPeer] = useState(" ");

    function FetchData(){
        return new Promise((resolve, reject) => {
            axios.put('http://localhost:8000/connector/edit_peer', 
            {
                peer1: As,
                peer2: peer
              },
            {
                headers: {
                    'Authorization': `Bearer ${Cookies.get('token')}`
                    },
                }
            )
                .then(response => {
                    resolve(response);
                    setData(response.data);
                }).catch(err => console.log(err))
            })
    }

    // This will fetch the data when there is any change in peer 
    useEffect( () => {FetchData()} ,[peer]);
   
    return (props.trigger) ? (<div>
        <div id={'OurPeersEditMainContainer'}>
            <div id={'OurPeersEditInsideData'}>
                    <div id={'EditDetails'}>
                        <h3>Edit Peer Detail</h3>
                    </div>
                    <div id={'OurPeersEditDataContainer'}>
                        <div>AS:</div>
                        <input id={'inputBox1'} type={'text'} value={props.AsData} readOnly></input>
                        <div>Peer:</div>
                        <input id={'inputBox2'} type={'text'} onChange={(e) => {
                                                                                    setPeer(e.target.value)
                                                                                }
                                                                        } required>
                        </input>
                    </div>
                    <div  id={'OurPeersEditButton'}>
                        <button id={'SubmitButton'} type="submit" onClick={() => {
                                                                if(peer === null || peer === " "){
                                                                    alert('Enter some value or press close button');
                                                                }
                                                                else{
                                                                    alert(data);
                                                                    props.setTrigger(false)
                                                                    navigate(0);
                                                                }
                                                                
                                                            }}>Submit</button>

                        <button type="submit" onClick={() => {
                                                                props.setTrigger(false)
                                                            }}>Close
                        </button>
                    </div>
            </div>
           </div>
   </div>) : '';
}
export default OurPeersEdit