import './App.css';
import React from 'react'
import Header from './SideNavComponents/Header';
import { Route, Routes } from 'react-router-dom';
import {AuthProvider} from './LoginComponent/Auth'
import RequireAuth from './LoginComponent/RequireAuth'
import Login from './LoginComponent/Login'
import Home from './LoginComponent/Home';
import ForgotPassword from './LoginComponent/ForgotPassword';
// import ShowConfigurations from './SideNavComponents/ShowConfiguration';
// import RouteLookup from './SideNavComponents/RouteLookup';
// import TrafficControl from './SideNavComponents/TrafficControl';
// import AuditLog from './SideNavComponents/AuditLog'
// import ContactUs from './SideNavComponents/ContactUs'
// import BackUpComponent from './SideNavComponents/BackupComponent';
import NoMatchRoute from './SideNavComponents/NomatchRoute';
import { Loader } from './Loader/Loader';
import  LookingGlass from './LookingGlass/LookingGlass';
import Kolkata from './LookingGlass/Kolkata';
import Chennai from './LookingGlass/Chennai';
import MaharaniBagh from './LookingGlass/MaharaniBagh';
import Shilong from './LookingGlass/Shilong';
import LookingGlassDetail from './LookingGlass/LookingGlassDetails';
import OurPeers from './LookingGlass/OurPeers';
import NewPeers from './LookingGlass/NewPeers';

const LazyShowConfiguration = React.lazy(() => import( './SideNavComponents/ShowConfiguration'));
const LazyRouteLookup = React.lazy(() => import('./SideNavComponents/RouteLookup'));
const LazyTrafficControl = React.lazy(() => import('./SideNavComponents/TrafficControl'));
const LazyAuditLog = React.lazy(() => import('./SideNavComponents/AuditLog'));
const LazyBackUpComponent = React.lazy(() => import('./SideNavComponents/BackupComponent') )
const LazyContactUs = React.lazy(() => import('./SideNavComponents/ContactUs') )
function App() {
  return (
    <div className="App">
    <Header></Header>
    <AuthProvider>
    <Routes>
      <Route>
        <Route path='*' element={<NoMatchRoute></NoMatchRoute>}></Route>
        <Route path='forgotPassword' element={<ForgotPassword></ForgotPassword>}></Route>
        <Route path='/' element={<Login></Login>}></Route>
        {/* -------------These are the routes for the looking glass components ---------------------*/}
        <Route path='home/LookingGlass/Kolkata/Details' element={<RequireAuth>
                                                                    <LookingGlassDetail state={'Kolkata'}></LookingGlassDetail>
                                                                  </RequireAuth>}>
        </Route>

        <Route path='home/LookingGlass/Chennai/Details' element={<RequireAuth>
                                                                  <LookingGlassDetail state={'Chennai'}></LookingGlassDetail>
                                                                </RequireAuth>}>
        </Route>
          
        <Route path='home/LookingGlass/MaharaniBagh/Details' element={<RequireAuth>
                                                                          <LookingGlassDetail state={'MaharaniBagh'}></LookingGlassDetail>
                                                                      </RequireAuth>}>
        </Route>

        <Route path='home/LookingGlass/Shilong/Details' element={<RequireAuth>
                                                                    <LookingGlassDetail state={'Shilong'}></LookingGlassDetail>
                                                                  </RequireAuth>}>
        </Route>                             


        {/*--------------- These all are the Our Peer Routes ------------------------*/}
        {/* <Route path={'home/TrafficControl/LookingGlass/Kolkata/Details/OurPeer'} element={<RequireAuth>
                                                                                                <OurPeers></OurPeers>
                                                                                          </RequireAuth>}>
        </Route>

        <Route path={'home/TrafficControl/LookingGlass/Kolkata/Details/OurPeer/NewPeer'} element={<RequireAuth>
                                                                                                <NewPeers></NewPeers>
                                                                                            </RequireAuth>}>
        </Route>
        <Route path={'home/TrafficControl/LookingGlass/Chennai/Details/OurPeer'} element={<RequireAuth>
                                                                                                <OurPeers></OurPeers>
                                                                                          </RequireAuth>}>
        </Route>

        <Route path={'home/TrafficControl/LookingGlass/Chennai/Details/OurPeer/NewPeer'} element={<RequireAuth>
                                                                                                <NewPeers></NewPeers>
                                                                                            </RequireAuth>}>
        </Route>
        <Route path={'home/TrafficControl/LookingGlass/MaharaniBagh/Details/OurPeer'} element={<RequireAuth>
                                                                                                <OurPeers></OurPeers>
                                                                                          </RequireAuth>}>
        </Route>

        <Route path={'home/TrafficControl/LookingGlass/MaharaniBagh/Details/OurPeer/NewPeer'} element={<RequireAuth>
                                                                                                <NewPeers></NewPeers>
                                                                                            </RequireAuth>}>
        </Route>
        <Route path={'home/TrafficControl/LookingGlass/Shilong/Details/OurPeer'} element={<RequireAuth>
                                                                                                <OurPeers></OurPeers>
                                                                                          </RequireAuth>}>
        </Route>

        <Route path={'home/TrafficControl/LookingGlass/Shilong/Details/OurPeer/NewPeer'} element={<RequireAuth>
                                                                                                <NewPeers></NewPeers>
                                                                                            </RequireAuth>}>
        </Route> */}


{/*-------------------------------------------- Our Peers Routes------------------------------------------------------------- */}
        <Route path='/home/OurPeer' element={<RequireAuth>
                                                  <OurPeers></OurPeers>
                                            </RequireAuth>}>
        </Route>

        <Route path='/home/OurPeer/NewPeer' element={<RequireAuth>
                                                  <NewPeers></NewPeers>
                                                </RequireAuth>}>
        </Route>



        <Route path='home/LookingGlass' element={<RequireAuth>
                                                      <LookingGlass>
                                                      </LookingGlass>
                                                </RequireAuth>}>

                <Route path='Kolkata' element={<RequireAuth>
                                                  <Kolkata></Kolkata>
                                              </RequireAuth>}>
                </Route>

                <Route path='Chennai' element={<RequireAuth>
                                                  <Chennai></Chennai>
                                                </RequireAuth>}>
                </Route>

                <Route path='MaharaniBagh' element={<RequireAuth>
                                                      <MaharaniBagh></MaharaniBagh> 
                                                    </RequireAuth>}>
                </Route>
                
                <Route path='Shilong' element={<RequireAuth>
                                                  <Shilong></Shilong>
                                                </RequireAuth>}>
                </Route>
        </Route>
        {/*--------------------- Home routes -----------------*/}
        <Route path='home' element={<RequireAuth><Home></Home></RequireAuth>}>
          
          <Route path='ShowConfigurations' element={<RequireAuth>
                                                      <React.Suspense fallback={<Loader></Loader>}>
                                                        <LazyShowConfiguration></LazyShowConfiguration>
                                                      </React.Suspense>
                                                    </RequireAuth>}>
          </Route>
          <Route path='RouterLookup' element={<RequireAuth>
                                                <React.Suspense fallback={<Loader></Loader>}>
                                                  <LazyRouteLookup></LazyRouteLookup>
                                                </React.Suspense>
                                              </RequireAuth>}>
          </Route>
          <Route path='TrafficControl' element={<RequireAuth>
                                                  <React.Suspense fallback={<Loader></Loader>}>
                                                    <LazyTrafficControl>
                                                    </LazyTrafficControl>
                                                  </React.Suspense>                                                  
                                                </RequireAuth>}>
          </Route>
          <Route path='Backup' element={<RequireAuth>
                                          <React.Suspense fallback={<Loader></Loader>}>
                                            <LazyBackUpComponent></LazyBackUpComponent>
                                          </React.Suspense>
                                        </RequireAuth>}>
          </Route>
          <Route path='AuditLog' element={<RequireAuth>
                                            <React.Suspense fallback={<Loader></Loader>}>
                                             <LazyAuditLog></LazyAuditLog>
                                            </React.Suspense>                                            
                                          </RequireAuth>}>
          </Route>
          <Route path='ContactUs' element={<RequireAuth>
                                            <React.Suspense fallback={<Loader></Loader>}>
                                              <LazyContactUs></LazyContactUs>
                                            </React.Suspense>
                                          </RequireAuth>}>
          </Route>
        </Route>
      </Route>
     </Routes>
    </AuthProvider>
     
    </div>
  );
}

export default App;
//Lazy loading
//this increases the initial loading time of our web application
//lazy loading help us to optimize our code , in this modules will be called only when it is clicked
// lazy is a function that takes another function as an argument(callback) this argument calls a dynamic import 
//a promise is returned by this dynamic import which is then converted into a module that contains a default 
// exported react component which is our about component