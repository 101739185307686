import { children, createContext, useContext, useState } from "react";

const AuthContext = createContext(null);
// to define the login and logout we have set the user 
// we are using the context api for maintaining the props throughout the tree
export const AuthProvider = ({children}) => {
    const[user , setUser] = useState();
    const[password , setPassword] = useState();
    
    //for setting the login 
    const login = (user) => {
        setUser(user);
    }

    // for entering the password
    const Password = (password) => {
        setPassword(password);
    }

    // for setting the logout 
    const logout = () => {
        setUser(null);
        setPassword(null);
    }
// here we are returning the auth provider
//here we are passing the three values to all our routes (children)  values = {user, login, logout} 
    return(
    <AuthContext.Provider value={{password,user,login,logout,Password}}>
        {children}
    </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext);
}