import { NavLink, Outlet} from "react-router-dom";
import { useAuth } from "./Auth";
import 'bootstrap/dist/css/bootstrap.css'
import '../Styles/ActiveNav.css'
import Cookies from "js-cookie";


import { useState } from "react";
const Home = () => {
    const auth = useAuth();
    const handleLogout = () =>{
        auth.logout(null);
        auth.Password(null);
        Cookies.remove('token',null);
        // localStorage.clear();
        localStorage.removeItem('LogginID');
        localStorage.removeItem('passoword');
    }

    const [display , setDiplay] = useState(true);

    return(
       <div>
        {/* <center><h4>Welcome {auth.user} </h4></center> */}
          <div id="Container">
            <button className={'toggle-button'} onClick={() => setDiplay(!display)}>
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </button>
            <nav id="Navbar-Container">
              <NavLink to={'/home/ShowConfigurations'} className={display ? 'LinkClass' : 'ButtonLinkClass'}>Show Configuration </NavLink>
              <NavLink to={'/home/RouterLookup'} className={display ? 'LinkClass' : 'ButtonLinkClass'}>Router Lookup</NavLink>
              <NavLink to={'/home/TrafficControl'} className={display ? 'LinkClass' : 'ButtonLinkClass'}>Traffic Control</NavLink>
              <NavLink to={'/home/LookingGlass'} className={display ? 'LinkClass' : 'ButtonLinkClass'}>Looking Glass</NavLink>
              <NavLink to={'/home/OurPeer'} className={display ? 'LinkClass' : 'ButtonLinkClass'}>Peer</NavLink>
              <NavLink to={'/home/Backup'} className={display ? 'LinkClass' : 'ButtonLinkClass'}>Backup</NavLink>
              <NavLink to={'/home/AuditLog'} className={display ? 'LinkClass' : 'ButtonLinkClass'}>Audit Logs</NavLink>
              <NavLink to={'/home/ContactUs'} className={display ? 'LinkClass' : 'ButtonLinkClass'}>Contact Us</NavLink>
            </nav>
            
            <Outlet></Outlet>
            <div id="Logout-Container">
              <button id="LogoutButton" type={"button"} className={"btn btn-light"} onClick={handleLogout}>Logout</button>
            </div>
        </div>
        
        {/* <button onClick={handleLogout}>Logout</button> */}
       </div>
    )
}
export default Home;