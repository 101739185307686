import '../Styles/Loader.css'
export const Loader = () => {
    return(
      <div id={'LoaderBody'}>
         <div className={"container"}>
       <div className={"wrapper"}>
          <div className={"loader"}>
             <div className={"dot"}></div>
          </div>
          <div className={"loader"}>
             <div className={"dot"}></div>
          </div>
          <div className={"loader"}>
             <div className={"dot"}></div>
          </div>
          <div className={"loader"}>
             <div className={"dot"}></div>
          </div>
          <div className={"loader"}>
             <div className={"dot"}></div>
          </div>
          <div className={"loader"}>
             <div className={"dot"}></div>
          </div>
       </div>
    </div>
      </div>
        
    )
}