import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "./Auth";
import { NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css'
import '../Styles/Login.css'
import axios from "axios";
import qs from 'qs'
import Cookies from "js-cookie";


const Login = () => {
    const [user , setUser] = useState()
    const [password , setPassword] = useState();
    const navigate = useNavigate();
    const auth = useAuth();
    const location = useLocation(); 
    //Api Call for login       
    function LoginAPICall(){
        const data = qs.stringify({username: user, password: password})
        return new Promise((resolve) => {

            axios.post('http://localhost:8000/login', data)
                .then(response => {
                    const token = response.data.access_token
                    // setAccessToken(token)
                    Cookies.set('token',token);
                })
            })        
    }

    //check if the path is set on state object use the same the redirect path 
     // if state is set access the path if not navigate to the root
    const redirectPath = location.state?.path || 'home'
    // this will set the values of user and password in our Auth 
    const handleLogin = (e) => {
        //Base Check for entering the username and password 
        if( user == null || password == null){
            alert('Enter Username and Password');
            return;
        }
        LoginAPICall();
        // FetchData();              //this will call the api 
        e.preventDefault();         //Call api for Login //Not authenticated redirect to home page----------------------------
        auth.login(user);        //calling the login context from auth.context and setting the value for the user 
        auth.Password(password);     //calling the password context from auth.Password and setting the value for the password
        localStorage.setItem('LogginID',user);
        localStorage.setItem('passoword',password);

        navigate(redirectPath, {replace:true} );

        //Timer for 30 Minutes window
        const countdown = 30*60*1000; 
        let timer;
        timer = localStorage.getItem('Timer')
        clearTimeout(timer);
        timer = setTimeout(() => {
                timer = null; 
                DeleteCredentials();
                localStorage.clear();
            }, countdown);

// This is set the timer queue number in our local storage for our reference . this will help us to clear our previous timer
        localStorage.setItem('Timer',timer);
    }

    // This will delete all our credentials
    const DeleteCredentials = () => {
                auth.logout(null);
                auth.Password(null);
                Cookies.remove('token',null);
                localStorage.clear();
                // localStorage.removeItem('LogginID');
                // localStorage.removeItem('passoword');
                alert('Session Timeout')
    }


    return(
        <div id="Login-container">
           <div id="inside-Login-container">
           <div id="Login">Login</div>
            <div className={"mb-3"}>
                <input  className={"form-control"} 
                        type="text" 
                        placeholder="Username" 
                        onChange={ e => setUser(e.target.value) }
                        aria-label="default input example"/>
            </div>

            <div className={"mb-3"}>
                <input  className={"form-control"} 
                        type="password" 
                        placeholder="Password" 
                        onChange={e => setPassword(e.target.value)}
                        aria-label="default input example"/>
            </div>               
                <button type="submit" className={"btn btn-danger"} onClick={handleLogin}>Login</button>              
            <div>
              {/* <NavLink to={"ForgotPassword"}>Forgot Password?</NavLink> */}
            </div>

           </div>
        </div>
    )
}

export default Login;