import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../LookingGlassStyles/NewPeer.css'
import Cookies from 'js-cookie';
import qs from 'qs'

const NewPeers = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [count , setCount] = useState(0)
    const [As,setAs] = useState(null);
    const [peer,setPeer] = useState(null);
    const NameOfLocation = location.pathname.split('/')[4];
    // console.log(NameOfLocation);
    
    // Call API-------------------------------------------
    const [data , setData] = useState(''); 


    function FetchData(){
        if(As === null || peer === null){
            return;
        }
        return new Promise((resolve, reject) => {
            axios.post('http://localhost:8000/connector/add_peer', 
            {
                peer1: As,
                peer2: peer
              },
              {
                headers: {
                    'Authorization': `Bearer ${Cookies.get('token')}`
                    },
                }
            )
                .then(response => {
                    resolve(response);
                    setData(response.data);
                    alert(response.data);
                    // setData(response.data)
                }).catch(err => console.log(err))
            })
    }

    useEffect( () => {FetchData()} ,[count]);


    return(
            <div id={'NewPeerMainContainer'}>
                <div>
                    <h3>New Peer Detail</h3>
                </div>
                <div id={'NewPeerDataContainer'}>
                    <div>AS:</div>
                    <input type={'text'} onChange={(e) => {
                                                            setAs(e.target.value)
                                                        }}></input>
                    <div>Peer:</div>
                    <input type={'text'} onChange={(e) => {
                                                            setPeer(e.target.value)
                                                            }}></input>
                </div>
                <div  id={'NewPeerButton'}>
                    <button type="submit" onClick={() => {
                                                                setCount(count+1)
                                                                // console.log(count)
                                                                 navigate(-1)
                                                        }}>Submit</button>
                </div>
                
            </div>
        )
}
export default NewPeers;