import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import '../LookingGlassStyles/OurPeer.css'
import OurPeersEdit from './OurPeersEdit';
import Cookies from 'js-cookie';

const OurPeers = () => {
    const [buttonPopUp , setButtonPopUp] = useState(false);
    const location = useLocation();
    const [DeleteResponse,setDeleteResponse] = useState();
    const[EditData,setEditData] = useState(0);
    const [Asdata , setAsdata] = useState();
    const [Peerdata , setPeerdata] = useState();
    const navigate = useNavigate();
    const NameOfLocation = location.pathname.split('/')[4];   //this will test us at which location our route is.


    const EditOurPeer = (AsData,peerData) => {
        // console.log(EditData);
        setAsdata(AsData);
        setPeerdata(peerData);
        console.log("As data :"+AsData);
        console.log("As data :"+peerData);
        // const OurPeerData = {
        //                         As : AsData,
        //                         peer : peerData
        //                         }
        // setsendEditData(OurPeerData)
        
        // console.log(sendEditData);
        setButtonPopUp(true);
        setEditData(EditData+1);
    }

    // Call API-----------------------------------------------

    const [data , setData] = useState(''); 

    function FetchData(){
        return new Promise((resolve, reject) => {
            axios.get('http://localhost:8000/connector/show_all_peers', {
                headers: {
                    'Authorization': `Bearer ${Cookies.get('token')}`
                }
            })
                .then(response => {
                    resolve(response);
                    setData(response.data);
                    // setData(response.data)
                }).catch(err => console.log(err))
            })
    }

    useEffect( () => {FetchData()} ,[EditData])

    const keys = Object.keys(data);
    const values = Object.values(data);

    const DeletePeerApiCall = (peer) => {
        return new Promise((resolve, reject) => {
            axios.delete(`http://localhost:8000/connector/delete_peer/AS=${peer}`, {
                headers: {
                    'Authorization': `Bearer ${Cookies.get('token')}`
                }
            })
                .then(response => {
                    resolve(response);                    
                    setDeleteResponse(response)
                }).catch(err => console.log(err))
            })
    }

    const DeleteData = (AsData) => {
        // console.log(AsData);
        // Api Call
        DeletePeerApiCall(AsData);
        // console.log(DeleteResponse);
        // alert(DeleteResponse);
        navigate(0);
    }


    return(
            <div id={'OurPeerMainContainer'}>
                <h2>OUR PEERS</h2>
                <div id='ButtonContainerPeer'>
                    <Link to={'NewPeer'}>Add New</Link>
                </div>
                <table id={'OurPeerTable'}>
                    <thead id={'TableHeadContainer'}>
                        <tr>
                            <td>AS</td>
                            <td>Peer</td>
                            <td>Action</td>
                        </tr>
                    </thead>

                    <tbody id={'TableBodyContainer'}>
                            {
                                keys.map( (Data,index) => {
                                    return <tr key={index}>
                                                <td>{Data}</td>
                                                <td>{values[index]}</td>
                                                <td>
                                                    <button onClick={() => EditOurPeer(Data,values[index])}>Edit</button>
                                                    <button onClick={() => DeleteData(Data)}>Delete</button>
                                                </td>
                                            </tr>
                                } )
                            }
                    </tbody>
                </table>
                <OurPeersEdit trigger={buttonPopUp} setTrigger={setButtonPopUp} Data={data} AsData={Asdata} peerData={Peerdata}></OurPeersEdit>
                <Outlet></Outlet>
            </div>
        )
}
export default OurPeers;