import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./Auth"

// In this we will check if the user is logged in or not
// if not it will redirect it to the login page
const RequireAuth = ({children}) => {
    const location = useLocation();
    const auth = useAuth();
    
    if((!window.localStorage.getItem('LogginID')) && !window.localStorage.getItem('passoword') ){
        return <Navigate to={'/'} state={{path : location.pathname}}></Navigate>
    }
    return children
}

export default RequireAuth;